
import { Navigation } from "@/mixins/navigation";
import Component, { mixins } from "vue-class-component";

@Component({})
export default class NotFound extends mixins(Navigation) {
  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }

  private get context(): string {
    return this.$store.getters["authentication/getContext"];
  }

  private get homeByContext(): string {
    return this.context == this.$constants.CONTEXT.FRONTOFFICE
      ? "/home"
      : this.context !== null
      ? "/staff/home"
      : "/home";
  }
}
